<template>
  <div>
    <ct-table :loaded="loaded" :items="addresses" :fields="addressFields">
      <template slot="cell(line1)" slot-scope="data">
        {{ data.item.line1 }}
      </template>
      <template slot="cell(line2)" slot-scope="data">
        {{ data.item.line2 }}
      </template>
      <template slot="cell(line3)" slot-scope="data">
        {{ data.item.line3 }}
      </template>
      <template slot="cell(city)" slot-scope="data">
        {{ data.item.city }}
      </template>
      <template slot="cell(country)" slot-scope="data">
        {{ data.item.country }}
      </template>
      <template slot="cell(state_province_region)" slot-scope="data">
        {{ data.item.state_province_region === '--' ? null : data.item.state_province_region }}
      </template>
      <template slot="cell(zip_postal_code)" slot-scope="data">
        {{ data.item.zip_postal_code === '--' ? null : data.item.zip_postal_code }}
      </template>
      <template slot="cell(kind)" slot-scope="data">
        {{ data.item.kind === "principle" ? "principal" : data.item.kind }}
      </template>
      <template slot="cell(actions)" slot-scope="data">
        <b-button
          v-b-modal.edit-address-modal
          variant="link"
          class="mr-2"
          @click="edit('address', data.item)"
        >
          Edit
        </b-button>
        <b-button
          v-if="addresses.length > 1"
          class="text-danger"
          variant="link"
          @click="deleteAddress(data.item)"
        >
          Delete
        </b-button>
        <feather-icon
          v-if="data.item.is_known_bad_address"
          :id="'address-attention-' + data.index"
          type="alert-octagon"
          width="25"
          height="25"
          stroke="red"
        />
        <b-tooltip
          :target="'address-attention-' + data.index"
          triggers="hover"
        >
          <p>This address needs to be updated. It is no longer a valid address.</p>
        </b-tooltip>
      </template>
    </ct-table>

    <b-button
      v-b-modal.add-address-modal
      variant="primary"
      size="$mq"
      :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
      @click="create('address')"
    >
      Add Address
    </b-button>

    <address-modal :bus="bus" @complete="complete('Thank you for updating your address.')" />
  </div>
</template>

<script>
import AddressModal from './AddressModal.vue'
import { mapGetters, mapActions } from 'vuex'
import CtTable from '../shared/CtTable.vue'
import FeatherIcon from '@/components/shared/FeatherIcon.vue'

import Vue from 'vue'

export default {
  name: 'AddressSettings',
  components: {
    CtTable,
    AddressModal,
    FeatherIcon,
  },
  data() {
    return {
      bus: new Vue(),
      selected: {},
      newAddress: {},
      errors: [],
      loaded: null,
      errored: false,
      addressFields: [
        { key: 'line1', sortable: false, label: 'Line 1' },
        { key: 'line2', sortable: false, label: 'Line 2' },
        { key: 'city', sortable: false, label: 'City' },
        { key: 'state_province_region', sortable: false, label: 'State' },
        { key: 'zip_postal_code', sortable: false, label: 'Zip Code' },
        { key: 'country', sortable: false, label: 'Country' },
        { key: 'kind', sortable: false, label: 'Kind' },
        { key: 'actions', sortable: false, label: '' },
      ],
    }
  },
  computed: {
    ...mapGetters('account', [
        'account',
        'addresses',
      ]
    ),
  },
  async mounted() {
    this.loaded = false
    await this.loadAccount()
    this.checkIfThereIsAKnownBadAddress()
    this.loaded = true
  },
  methods: {
    ...mapActions('account', [
        'loadAccount',
        'deletePersonAddress',
      ],
    ),
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    complete(message) {
      this.makeToast('primary', 'Success!', message)
    },
    async edit(name, info) {
      this.bus.$emit(name, Object.assign({}, { ...info }), 'edit')
    },
    async create(name) {
      this.bus.$emit(name)
    },
    async deleteAddress(address) {
      this.$bvModal.hide('destroyAddress')
      try {
        await this.deletePersonAddress({ address_id: address.id })
        this.makeToast('primary', 'Success', 'Address Deleted!')
      } catch {
        this.makeToast('danger', 'Failure', 'The address can not be deleted.')
      }
    },
    checkIfThereIsAKnownBadAddress() {
      this.addresses.forEach((address) => {
        if (address.is_known_bad_address) {
          this.tabIndex = 2
          this.edit('address', address)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 0;
  margin-bottom: 0;
}
h4.card-title {
  font-size: 105%;
}
@media only screen and (max-width: 550px) {
}
#account-container {
  padding-top: 1rem !important;
}
.alert-icon {
  stroke: red;
}
</style>
