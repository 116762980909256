var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ct-table", {
        attrs: {
          loaded: _vm.loaded,
          items: _vm.addresses,
          fields: _vm.addressFields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(line1)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.line1) + "\n    ")]
            },
          },
          {
            key: "cell(line2)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.line2) + "\n    ")]
            },
          },
          {
            key: "cell(line3)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.line3) + "\n    ")]
            },
          },
          {
            key: "cell(city)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.city) + "\n    ")]
            },
          },
          {
            key: "cell(country)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.country) + "\n    ")]
            },
          },
          {
            key: "cell(state_province_region)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      data.item.state_province_region === "--"
                        ? null
                        : data.item.state_province_region
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(zip_postal_code)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      data.item.zip_postal_code === "--"
                        ? null
                        : data.item.zip_postal_code
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(kind)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      data.item.kind === "principle"
                        ? "principal"
                        : data.item.kind
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.edit-address-modal",
                        modifiers: { "edit-address-modal": true },
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: { variant: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.edit("address", data.item)
                      },
                    },
                  },
                  [_vm._v("\n        Edit\n      ")]
                ),
                _vm.addresses.length > 1
                  ? _c(
                      "b-button",
                      {
                        staticClass: "text-danger",
                        attrs: { variant: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAddress(data.item)
                          },
                        },
                      },
                      [_vm._v("\n        Delete\n      ")]
                    )
                  : _vm._e(),
                data.item.is_known_bad_address
                  ? _c("feather-icon", {
                      attrs: {
                        id: "address-attention-" + data.index,
                        type: "alert-octagon",
                        width: "25",
                        height: "25",
                        stroke: "red",
                      },
                    })
                  : _vm._e(),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: "address-attention-" + data.index,
                      triggers: "hover",
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "This address needs to be updated. It is no longer a valid address."
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.add-address-modal",
              modifiers: { "add-address-modal": true },
            },
          ],
          class: _vm.$mq !== "sm" ? "mr-2 rounded" : "",
          attrs: { variant: "primary", size: "$mq" },
          on: {
            click: function ($event) {
              return _vm.create("address")
            },
          },
        },
        [_vm._v("\n    Add Address\n  ")]
      ),
      _c("address-modal", {
        attrs: { bus: _vm.bus },
        on: {
          complete: function ($event) {
            return _vm.complete("Thank you for updating your address.")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }